import { ChangeEvent, useMemo, useState } from "react"
import { UseFormReturn } from "react-hook-form"

import { useAppSelector } from "../../../hooks/storeHooks"
import { useUserIsUnderage } from "../../../hooks/useUserIsUnderage"
import { selectUser } from "../../../store/user/user.selectors"
import { UserDataValues } from "./FillUserDataInformationPanel.component"
import { checkFeatureAvailability } from "../../../store/clinic/clinicSettings.utils"
import { revertDateFormat } from "../../../utils/dayJs/ManipulateDate"
import { getGenderFromPesel } from "../../../utils/getGenderFromPesel"
import { formatPolandPostalCode } from "../../commonFormItems/postalCodeInput/PostalCodeInput.utils"
import { compareAddresses, getDocumentTypesForCountry, getUserDocumentData, setProperDefaultDocumentType, setProperDocumentType } from "../../user/userProfile/userPersonalData/UserPersonalData.utils"
import { MainCountriesTypes, UserDataType, UserDocumentType, UserParentDataType } from "../../../store/user/user.types"
import { ObjectKeysToTuple } from "../../../types/ObjectKeysToTuple"

type UserAddressValues = {
  street: string;
  houseNumber: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  country: string;
  showNumber?: boolean;
}

type UserParentAddressValues = {
  parentStreet: string;
  parentHouseNumber: string;
  parentApartmentNumber: string;
  parentPostalCode: string;
  parentCity: string;
  parentCountry: string;
}

export enum ErrorFields {
  "address.postal_code" = "parentPostalCode"
}

const getParsedValues = (keys: string[], values: string[]) => keys.reduce((accumulator, element, index) => {
  return {...accumulator, [element]: values[index]}
}, {})

export const useFillUserDataInformationPanel = (form: UseFormReturn<UserDataValues>) => {
  const userData = useAppSelector(selectUser)
  const userParentData = userData?.user_parent
  const sameAddress = compareAddresses(userData?.address, userParentData?.address)
  const [sameAddressOn, setSameAddressOn] = useState(sameAddress)
  const [lastAddressState, setLastAddressState] = useState<UserDataValues | null>(null)
  const benefitSystemIntegrationEnabled = checkFeatureAvailability().enableBenefitSystemIntegration
  const userAddressFields: ObjectKeysToTuple<UserAddressValues> = ["street", "houseNumber", "apartmentNumber", "postalCode", "city", "country"]
  const userParentAddressFields: ObjectKeysToTuple<UserParentAddressValues> = ["parentStreet", "parentHouseNumber", "parentApartmentNumber", "parentPostalCode", "parentCity", "parentCountry"]

  const handleChange = (onChange: (value: boolean) => void) => (event: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    const userAddressValues = form.getValues(userAddressFields)
    const lastParentAddressValues = form.getValues(userParentAddressFields)

    const userAddressData = getParsedValues(userParentAddressFields, userAddressValues)
    const lastParentAddress = getParsedValues(userParentAddressFields, lastParentAddressValues)

    setSameAddressOn(newValue)

    if(!newValue) {
      form.reset({
        ...form.getValues(),
        ...lastAddressState,
      })
    } else {
      form.reset({
        ...form.getValues(),
        ...userAddressData,
      })
      
      setLastAddressState(lastParentAddress)
    }

    onChange(newValue)
  }

  const getPatientPayloadData = (
    values: UserDataValues, 
    userData: UserDataType, 
    showPhoneNumber: boolean,
    isDifferentDateFormat?: boolean,
    canPatientEditDataUntilFirstConsultationFinished?: boolean
  ) => {
    const patientAddress = {
      street: userData.address?.street === values.street
        ? undefined
        : (values.street || undefined),
      houseNumber: userData.address?.house_number === values.houseNumber
        ? undefined
        : (values.houseNumber || undefined),
      apartmentNumber: userData.address?.apartment_number === values.apartmentNumber
        ? undefined
        : (values.apartmentNumber || undefined),
      postalCode: userData.address?.postal_code === values.postalCode
        ? undefined
        : (values.postalCode || undefined),
      city: userData.address?.city === values.city
        ? undefined
        : (values.city || undefined),
      country: userData.address?.country === values.country
        ? undefined
        : (values.country || undefined)
    }
    
    const patientGender = (values.pesel && values.documentType === UserDocumentType.PESEL)
      ? (!userData.pesel || values.pesel) ? (getGenderFromPesel(values.pesel) ?? undefined) : undefined
      : (!userData.pesel || !userData.gender !== null || values.gender !== null) ? (values.gender ?? undefined) : undefined
    
    if (canPatientEditDataUntilFirstConsultationFinished) {
      return {
        firstName: !values.firstName || userData.first_name === values.firstName
          ? undefined
          : (values.firstName || undefined),
        lastName: !values.lastName || userData.last_name === values.lastName
          ? undefined
          : (values.lastName || undefined),
        identityDocumentType: (values.documentType === UserDocumentType.PESEL || !values.identityNumber || !values.documentType)
          ? undefined
          : (setProperDocumentType(values.documentType) || undefined),
        pesel: (!values.pesel || userData.pesel === values.pesel || values.pesel.length < 11 || values.documentType !== UserDocumentType.PESEL)
          ? undefined
          : (values.pesel || undefined),
        hasPesel: !!(values.pesel && values.documentType === UserDocumentType.PESEL && values.pesel.length === 11),
        identityNumber: (!values.identityNumber || values.documentType === UserDocumentType.PESEL)
          ? undefined
          : (values.identityNumber || undefined),
        identityDocumentCountryIssuer: (values.documentType === UserDocumentType.PESEL || !values.identityNumber || !values.nationality)
          ? undefined
          : values.nationality,
        birthDate: !values.birthDate || userData.birth_date === (isDifferentDateFormat ? revertDateFormat(values.birthDate) : values.birthDate)
          ? undefined
          : new Date(isDifferentDateFormat ? revertDateFormat(values.birthDate) : values.birthDate),
        gender: patientGender,
        nationality: !values.nationality || userData.nationality === values.nationality
          ? undefined
          : (values.nationality || undefined),
        defaultTimezone: !values.defaultTimezone || userData.default_timezone === values.defaultTimezone
          ? undefined
          : (values.defaultTimezone || undefined),
        phoneNumber: (benefitSystemIntegrationEnabled || showPhoneNumber) ? {
          country: values?.phoneCountryCode,
          number: values?.phoneNumber
        } : undefined,
        address: patientAddress
      }
    }

    return {
      firstName: userData.first_name
        ? undefined
        : (values.firstName || undefined),
      lastName: userData.last_name
        ? undefined
        : (values.lastName || undefined),
      identityDocumentType: (userData.pesel || (userData.identity_document_type && userData.identity_number) || values.documentType === UserDocumentType.PESEL || (!values.identityNumber && values.documentType !== UserDocumentType.PESEL) || !values.documentType)
        ? undefined
        : (setProperDocumentType(values.documentType) || undefined),
      pesel: (userData.pesel || userData.identity_document_type || values.documentType !== UserDocumentType.PESEL)
        ? undefined
        : (values.pesel || undefined),
      hasPesel: (!userData.pesel && values.pesel && values.documentType === UserDocumentType.PESEL && !userData.identity_document_type)
        ? true
        : undefined,
      identityNumber: (userData.pesel || userData.identity_document_type || values.documentType === UserDocumentType.PESEL)
        ? undefined
        : (values.identityNumber || undefined),
      identityDocumentCountryIssuer: (userData.pesel || userData.identity_document_type || values.documentType === UserDocumentType.PESEL || !values.identityNumber)
        ? undefined
        : values.nationality,
      birthDate: (userData.pesel || userData.identity_document_type || values.documentType === UserDocumentType.PESEL || userData.birth_date || !values.birthDate)
        ? undefined
        : new Date(isDifferentDateFormat ? revertDateFormat(values.birthDate) : values.birthDate),
      gender: patientGender,
      nationality: userData.nationality === values.nationality
        ? undefined
        : (values.nationality || undefined),
      defaultTimezone: userData.default_timezone === values.defaultTimezone
        ? undefined
        : (values.defaultTimezone || undefined),
      phoneNumber: (benefitSystemIntegrationEnabled || showPhoneNumber) ? {
        country: values?.phoneCountryCode,
        number: values?.phoneNumber
      } : undefined,
      address: patientAddress
    }
  }

  const getParentPayloadData = (
    values: UserDataValues, 
    userParentData: UserParentDataType | null,
    isDifferentDateFormat?: boolean,
    canPatientEditDataUntilFirstConsultationFinished?: boolean
  ) => {
    const parentAddress = {
      street: values.sameAddressCheckbox
        ? userParentData?.address?.street !== values.street ? values.street : undefined
        : userParentData?.address?.street !== values.parentStreet ? values.parentStreet : undefined,
      houseNumber: values.sameAddressCheckbox
        ? userParentData?.address?.house_number !== values.houseNumber ? values.houseNumber : undefined
        : userParentData?.address?.house_number !== values.parentHouseNumber ? values.parentHouseNumber : undefined,
      apartmentNumber: values.sameAddressCheckbox
        ? userParentData?.address?.apartment_number !== values.apartmentNumber ? values.apartmentNumber : undefined
        : userParentData?.address?.apartment_number !== values.parentApartmentNumber ? values.parentApartmentNumber : undefined,
      postalCode: values.sameAddressCheckbox
        ? userParentData?.address?.postal_code !== values.postalCode ? values.postalCode : undefined
        : userParentData?.address?.postal_code !== values.parentPostalCode ? values.parentPostalCode : undefined,
      city: values.sameAddressCheckbox
        ? userParentData?.address?.city !== values.city ? values.city : undefined
        : userParentData?.address?.city !== values.parentCity ? values.parentCity : undefined,
      country: values.sameAddressCheckbox
        ? userParentData?.address?.country !== values.country ? values.country : undefined
        : userParentData?.address?.country !== values.parentCountry ? values.parentCountry : undefined,
    }
    
    const parentGender = (values.parentPesel && values.parentDocumentType === UserDocumentType.PESEL)
      ? (!userParentData?.pesel || values.parentPesel) ? (getGenderFromPesel(values.parentPesel) ?? undefined) : undefined
      : (!userParentData?.pesel && (userParentData?.gender === null || !userParentData?.gender) ? ((values.parentGender || values.parentGender === 0) ? values.parentGender : undefined) : undefined)
    
    if (canPatientEditDataUntilFirstConsultationFinished) {
      return {
        firstName: !values.parentFirstName || values.parentFirstName === userParentData?.first_name
          ? undefined
          : (values.parentFirstName || undefined),
        lastName: !values.parentLastName || values.parentLastName === userParentData?.last_name
          ? undefined
          : (values.parentLastName || undefined),
        identityDocumentType: (values.parentDocumentType === UserDocumentType.PESEL || !values.parentIdentityNumber || !values.parentDocumentType)
          ? undefined
          : (setProperDocumentType(values.parentDocumentType) || undefined),
        pesel: (values.parentDocumentType !== UserDocumentType.PESEL || !values.parentPesel || values.parentPesel.length < 11)
          ? undefined
          : (values.parentPesel || undefined),
        identityNumber: (values.parentDocumentType === UserDocumentType.PESEL || !values.parentIdentityNumber)
          ? undefined
          : (values.parentIdentityNumber || undefined),
        hasPesel: (!(values.parentDocumentType !== UserDocumentType.PESEL || !values.parentPesel || values.parentPesel.length < 11)),
        identityDocumentCountryIssuer: (values.parentDocumentType === UserDocumentType.PESEL || !values.parentIdentityNumber || !values.parentNationality)
          ? undefined
          : values.parentNationality,
        birthDate: (values.parentDocumentType === UserDocumentType.PESEL || !values.parentBirthDate || userParentData?.birth_date)
          ? undefined
          : isDifferentDateFormat ? revertDateFormat(values.parentBirthDate) : values.parentBirthDate,
        gender: parentGender,
        nationality: !values.parentNationality || userParentData?.nationality === values.parentNationality
          ? undefined
          : (values.parentNationality || undefined),
        defaultTimezone: !values.parentDefaultTimezone || userParentData?.default_timezone === values.parentDefaultTimezone
          ? undefined
          : (values.parentDefaultTimezone || undefined),
        address: parentAddress
      }
    }
    
    return {
      firstName: userParentData?.first_name
        ? undefined
        : (values.parentFirstName || undefined),
      lastName: userParentData?.last_name
        ? undefined
        : (values.parentLastName || undefined),
      identityDocumentType: (userParentData?.pesel || (userParentData?.identity_document_type && userParentData?.identity_number) || values.parentDocumentType === UserDocumentType.PESEL || (!values.parentIdentityNumber && values.parentDocumentType !== UserDocumentType.PESEL) || !values.parentDocumentType)
        ? undefined
        : (setProperDocumentType(values.parentDocumentType) || undefined),
      pesel: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType !== UserDocumentType.PESEL)
        ? undefined
        : (values.parentPesel || undefined),
      identityNumber: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL)
        ? undefined
        : (values.parentIdentityNumber || undefined),
      identityDocumentCountryIssuer: (userParentData?.pesel || userParentData?.identity_document_type || values.parentDocumentType === UserDocumentType.PESEL || !values.parentIdentityNumber)
        ? undefined
        : values.parentNationality,
      birthDate: (userParentData?.pesel || values.parentDocumentType === UserDocumentType.PESEL || userParentData?.birth_date || !values.parentBirthDate)
        ? undefined
        : isDifferentDateFormat ? revertDateFormat(values.parentBirthDate) : values.parentBirthDate,
      gender: parentGender,
      nationality: userParentData?.nationality === values.parentNationality
        ? undefined
        : (values.parentNationality || undefined),
      defaultTimezone: userParentData?.default_timezone === values.parentDefaultTimezone
        ? undefined
        : (values.parentDefaultTimezone || undefined),
      address: parentAddress
    }
  }

  return {
    handleChange,
    sameAddressOn,
    userAddressFields,
    userParentAddressFields,
    getPatientPayloadData,
    getParentPayloadData
  }
}

export const useUserFormDataDefaultValues = () => {
  const userData = useAppSelector(selectUser)
  const userParentData = userData?.user_parent
  const userDocumentData = useMemo(() => getUserDocumentData(userData), [])
  const userParentDocumentData = useMemo(() => getUserDocumentData(userParentData), [])
  const userHasNoPesel = userData?.identity_document_type !== null
  const userParentHasNoPesel = userParentData?.identity_document_type !== null
  const sameAddress = compareAddresses(userData?.address, userParentData?.address)
  const underageUser = useUserIsUnderage()

  let defaultValues: UserDataValues = {
    sameAddressCheckbox: sameAddress,
    underageUserCheckbox: underageUser ?? false,
    // patient personal data values
    firstName: userData?.first_name ?? "",
    lastName: userData?.last_name ?? "",
    nationality: userData?.nationality ?? "",
    pesel: userData?.pesel ?? "",
    documentType: setProperDefaultDocumentType(userData?.nationality, userData?.pesel ?? userData?.identity_document_type)
      ?? setProperDefaultDocumentType(userData?.nationality, getDocumentTypesForCountry(userData?.nationality)[0].value)
      ?? "",
    identityNumber: userData?.identity_number ?? "",
    birthDate: userData?.birth_date ?? "",
    gender: userData?.gender !== undefined ? userData?.gender : null,
    defaultTimezone: userData?.default_timezone ?? "",
    // patient address data values
    street: userData?.address?.street ?? "",
    houseNumber: userData?.address?.house_number ?? "",
    apartmentNumber: userData?.address?.apartment_number ?? "",
    postalCode: 
      (userData?.address?.country === MainCountriesTypes.PL && userData?.address?.postal_code)
        ? formatPolandPostalCode(userData?.address?.postal_code)
        : (userData?.address?.postal_code ?? ""),
    city: userData?.address?.city ?? "",
    country: userData?.address?.country ?? userData?.nationality ?? "",
    phoneCountryCode: userData?.phone_country ?? "",
    phoneNumber: userData?.phone_national_number ?? "",

    // parent personal data values
    parentFirstName: userParentData?.first_name ?? "",
    parentLastName: userParentData?.last_name ?? "",
    parentNationality: userParentData?.nationality ?? userData?.nationality ?? "",
    parentPesel: userParentData?.pesel ?? "",
    parentDocumentType: setProperDefaultDocumentType(userParentData?.nationality, userParentData?.identity_document_type) 
      ?? setProperDefaultDocumentType(userParentData?.nationality, getDocumentTypesForCountry(userParentData?.nationality)[0].value)
      ?? "",
    parentIdentityNumber: userParentData?.identity_number ?? "",
    parentBirthDate: userParentData?.birth_date ?? "",
    parentGender: userParentData?.gender ?? null,
    parentDefaultTimezone: userParentData?.default_timezone ?? userData?.default_timezone ?? "",
    // parent address data values
    parentStreet: userParentData?.address?.street ?? "",
    parentHouseNumber: userParentData?.address?.house_number ?? "",
    parentApartmentNumber: userParentData?.address?.apartment_number ?? "",
    parentPostalCode: 
      (userParentData?.address?.country === MainCountriesTypes.PL && userParentData?.address?.postal_code)
        ? formatPolandPostalCode(userParentData?.address?.postal_code)
        : (userParentData?.address?.postal_code ?? ""),
    parentCity: userParentData?.address?.city ?? "",
    parentCountry: userParentData?.address?.country ?? userParentData?.nationality ?? userData?.nationality ?? "",
  }
  
  defaultValues =
    userHasNoPesel ? {
      ...defaultValues,
      identityNumber: userDocumentData?.value ?? ""
    } : {
      ...defaultValues,
      pesel: userDocumentData?.value ?? "",
    }
    
  defaultValues =
    userParentHasNoPesel ? {
      ...defaultValues,
      parentIdentityNumber: userParentDocumentData?.value ?? ""
    } : {
      ...defaultValues,
      parentPesel: userParentDocumentData?.value ?? "",
    }
      
  return {
    defaultValues
  }
}